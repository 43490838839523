"use client";

import { createSlice } from '@reduxjs/toolkit';
import { isValidToken, setSession, getSession } from '@layouts/CmsLayout/Auth/utils';
import cs_axs from '@axios/cs_axs';
import jwtDecode from 'jwt-decode';

const init = {
    usuario: {},
    token: null,
    roles: [],
    loading: false,
    error: false,
    authUsers: [
        'danielarelam@gmail.com',
        'fagonzalh@gmail.com',
        'mmayorga@fondosgenesis.com'
    ],
    isAuth: false,
    rutasCms: [],
};

const slice = createSlice({
    name: "sesion",
    initialState: init,
    reducers: {
        loginFetch(state) {
            state.loading = true;
            state.error = false;
        },
        loginOk(state, action) {

            state.loading = false;
            state.error = false;
            state.usuario = action.payload.usuario;
            state.token = action.payload.token;
            state.roles = action.payload.roles;
            state.mechanism = action.payload.mechanism;
            state.isAuth = true;//action.payload.isAuth;
            state.rutasCms = rutas(true);
        },
        loginError(state, action) {
            state.error = action.payload
            state.loading = false;
            state.usuario = {};
            state.token = null;
            state.roles = [];
            state.mechanism = null;

        },
        resetState(state) {
            state = init;
        }
    }
});


export default slice.reducer;


const rutas = (isAuth) => (
    [
        {
            titulo: 'Contenidos',
            slug: "contenidos",
            show: isAuth
        },
        {
            titulo: 'Galería',
            slug: "galeria",
            show: isAuth
        },
        {
            titulo: 'Documentos',
            slug: "documentos",
            show: isAuth
        },
        {
            titulo: 'Clientes',
            slug: "clientepotencial",
            show: true
        },
        {
            titulo: 'Usuarios',
            slug: "usuarios",
            show: isAuth
        },
        {
            titulo: 'Notificaciones',
            slug: "notificaciones",
            show: isAuth
        },
        {
            titulo: 'Salir',
            slug: "logout",
            show: true
        }
    ]
)


export const loginUsuario = (payload, callback) => async (dispatch) => {
    const { loginFetch, loginOk } = slice.actions;

    try {
        const token = getSession();
        // (token);
        ///LOGIN GUEST
        if (!token && !payload) {
            const response = await cs_axs.post(`/session/login`, {}, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'guard': 'GUEST' } });
            console.log(response.data)
            if (response.data) {
                const token = response.data.token;

                if (isValidToken(token)) {
                    localStorage.setItem('token', token);
                    cs_axs.defaults.headers.common.Authorization = `Bearer ${token}`;
                    cs_axs.defaults.headers.common.guard = "GUEST"


                } else {
                    localStorage.removeItem('token', token);
                    delete cs_axs.defaults.headers.common.Authorization;
                    delete cs_axs.defaults.headers.common.guard;
                }

                dispatch(loginOk(response.data));
            }
        }

        ///LOGIN MANAGER
        if (payload) {
            dispatch(loginFetch());
            const response = await cs_axs.post(`/session/login`, payload, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'guard': 'MANAGER' } });

            response.data.isAuth = init.authUsers.includes(response.data.usuario.correo);

            dispatch(loginOk(response.data));
            if (isValidToken(response.data.token)) setSession(response.data.token);
            return;
        }


        if (token) {
            const decoded = jwtDecode(token);
            // (decoded);
            const guard = decoded.id ? 'MANAGER' : 'GUEST';

            if (isValidToken(token)) {
                cs_axs.defaults.headers.common.Authorization = `Bearer ${token}`;
                cs_axs.defaults.headers.guard = guard;

                dispatch(loginFetch());

                let { data } = await cs_axs.get(`/session/me`);

                data.isAuth = init.authUsers.includes(data.usuario.correo);

                dispatch(loginOk(data));


            } else {
                localStorage.removeItem('token', token);
                // delete cs_axs.defaults.headers.common.Authorization;
                // delete cs_axs.defaults.headers.guard;

                const response = await cs_axs.post(`/session/login`, {}, { headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'guard': 'GUEST' } });


                if (response.data) {
                    const token = response.data.token;

                    if (isValidToken(token)) {
                        localStorage.setItem('token', token);
                        cs_axs.defaults.headers.common.Authorization = `Bearer ${token}`;
                        cs_axs.defaults.headers.common.guard = "GUEST"
                    } else {
                        localStorage.removeItem('token', token);
                        delete cs_axs.defaults.headers.common.Authorization;
                        delete cs_axs.defaults.headers.common.guard;
                    }

                    dispatch(loginOk(response.data));
                }
            }

        }
        return;
    } catch (error) {
        console.log(error);
    }
};


export const resetSession = () => async (dispatch) => {
    const { resetState } = slice.actions;
    dispatch(resetState());
}