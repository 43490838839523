
export const fondosObj = {
    "000001": "FONDO HORIZONTE",
    "000029": "FONDO MASTER",
    "000033": "FONDO ESTRATÉGICO",
    "000038": "FONDO RENTAPLUS",
    "000040": "FONDO SUPERIOR"
};

export const fondos = [
    {
        codigoFondo: '000001',
        descripcion: 'FONDO HORIZONTE'
    },
    {
        codigoFondo: '000029',
        descripcion: 'FONDO MASTER'
    },
    {
        codigoFondo: '000033',
        descripcion: 'FONDO ESTRATÉGICO'
    },
    {
        codigoFondo: '000038',
        descripcion: 'FONDO RENTAPLUS'
    },
    {
        codigoFondo: '000040',
        descripcion: 'FONDO SUPERIOR'
    }
]


export const fondoHorizonte = {
    codigoFondo: '000001',
    descripcion: 'FONDO HORIZONTE'
}

export const fondoMaster = {
    codigoFondo: '000029',
    descripcion: 'FONDO MASTER'
}

export const fondoEstrategico = {
    codigoFondo: '000033',
    descripcion: 'FONDO ESTRATÉGICO'
}

export const fondoRentaPlus = {
    codigoFondo: '000038',
    descripcion: 'FONDO RENTAPLUS'
}

export const fondoSuperior = {
    codigoFondo: '000040',
    descripcion: 'FONDO SUPERIOR'
}


export const paises = [
    {
        codigo: "ME",
        contenido: "MONTENEGRO",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MS",
        contenido: "MONTSERRAT",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MZ",
        contenido: "MOZAMBIQUE",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NA",
        contenido: "NAMIBIA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NR",
        contenido: "NAURU",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NP",
        contenido: "NEPAL",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NI",
        contenido: "NICARAGUA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NE",
        contenido: "NIGER",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NG",
        contenido: "NIGERIA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NU",
        contenido: "NIUE",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NO",
        contenido: "NORUEGA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NC",
        contenido: "NUEVA CALEDONIA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NZ",
        contenido: "NUEVA ZELANDA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "OM",
        contenido: "OMÁN",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "NL",
        contenido: "PAÍSES BAJOS",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "PK",
        contenido: "PAKISTÁN",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "PW",
        contenido: "PALAU",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "PS",
        contenido: "PALESTINA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "PA",
        contenido: "PANAMÁ",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "PG",
        contenido: "PAPÚA NUEVA GUINEA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "PY",
        contenido: "PARAGUAY",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "PE",
        contenido: "PERÚ",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "PF",
        contenido: "POLINESIA FRANCESA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "PL",
        contenido: "POLONIA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "PT",
        contenido: "PORTUGAL",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "QA",
        contenido: "QATAR",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "GB",
        contenido: "REINO UNIDO",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "CF",
        contenido: "REPÚBLICA CENTROAFRICANA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "CZ",
        contenido: "REPÚBLICA CHECA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "DO",
        contenido: "REPÚBLICA DOMINICANA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "RE",
        contenido: "REUNIÓN",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "RW",
        contenido: "RUANDA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "RO",
        contenido: "RUMANÍA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "RU",
        contenido: "RUSIA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "EH",
        contenido: "SAHARA OCCIDENTAL",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "WS",
        contenido: "SAMOA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "AS",
        contenido: "SAMOA AMERICANA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "BL",
        contenido: "SAN BARTOLOMÉ",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "KN",
        contenido: "SAN CRISTÓBAL Y NIEVES",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "SM",
        contenido: "SAN MARINO",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "MF",
        contenido: "SAN MARTÍN (FRANCIA)",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "PM",
        contenido: "SAN PEDRO Y MIQUELÓN",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "VC",
        contenido: "SAN VICENTE Y LAS GRANADINAS",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "SH",
        contenido: "SANTA ELENA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "LC",
        contenido: "SANTA LUCÍA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "ST",
        contenido: "SANTO TOMÉ Y PRÍNCIPE",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "SN",
        contenido: "SENEGAL",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "RS",
        contenido: "SERBIA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "SC",
        contenido: "SEYCHELLES",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "SL",
        contenido: "SIERRA LEONA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "SG",
        contenido: "SINGAPUR",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "SY",
        contenido: "SIRIA",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "SO",
        contenido: "SOMALIA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "LK",
        contenido: "SRI LANKA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "ZA",
        contenido: "SUDÁFRICA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "SD",
        contenido: "SUDÁN",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "SE",
        contenido: "SUECIA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "CH",
        contenido: "SUIZA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "SR",
        contenido: "SURINÁM",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "SJ",
        contenido: "SVALBARD Y JAN MAYEN",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "SZ",
        contenido: "SWAZILANDIA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TJ",
        contenido: "TADJIKISTÁN",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TH",
        contenido: "TAILANDIA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TW",
        contenido: "TAIWÁN",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TZ",
        contenido: "TANZANIA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "IO",
        contenido: "TERRITORIO BRITÁNICO DEL OCÉANO ÍNDICO",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TF",
        contenido: "TERRITORIOS AUSTRALES Y ANTÁRTICAS FRANCESES",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TL",
        contenido: "TIMOR ORIENTAL",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TG",
        contenido: "TOGO",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TK",
        contenido: "TOKELAU",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TO",
        contenido: "TONGA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TT",
        contenido: "TRINIDAD Y TOBAGO",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TN",
        contenido: "TUNEZ",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TM",
        contenido: "TURKMENISTÁN",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TR",
        contenido: "TURQUÍA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "TV",
        contenido: "TUVALU",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "UA",
        contenido: "UCRANIA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "UG",
        contenido: "UGANDA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "UY",
        contenido: "URUGUAY",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "UZ",
        contenido: "UZBEKISTÁN",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "VU",
        contenido: "VANUATU",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "VE",
        contenido: "VENEZUELA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "VN",
        contenido: "VIETNAM",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "WF",
        contenido: "WALLIS Y FUTUNA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "YE",
        contenido: "YEMEN",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "DJ",
        contenido: "YIBUTI",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "ZM",
        contenido: "ZAMBIA",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "ZW",
        contenido: "ZIMBABUE",
        fecha_ingreso: "2014/04/02 04:54:34"
    },
    {
        codigo: "AF",
        contenido: "AFGANISTÁN",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AL",
        contenido: "ALBANIA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "DE",
        contenido: "ALEMANIA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "DZ",
        contenido: "ALGERIA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AD",
        contenido: "ANDORRA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AO",
        contenido: "ANGOLA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AI",
        contenido: "ANGUILA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AQ",
        contenido: "ANTÁRTIDA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AG",
        contenido: "ANTIGUA Y BARBUDA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AN",
        contenido: "ANTILLAS NEERLANDESAS",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "SA",
        contenido: "ARABIA SAUDITA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AR",
        contenido: "ARGENTINA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AM",
        contenido: "ARMENIA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AW",
        contenido: "ARUBA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AU",
        contenido: "AUSTRALIA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AT",
        contenido: "AUSTRIA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "AZ",
        contenido: "AZERBAYÁN",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BE",
        contenido: "BÉLGICA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BS",
        contenido: "BAHAMAS",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BH",
        contenido: "BAHREIN",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BD",
        contenido: "BANGLADESH",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BB",
        contenido: "BARBADOS",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BZ",
        contenido: "BELICE",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BJ",
        contenido: "BENÍN",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BT",
        contenido: "BHUTÁN",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BY",
        contenido: "BIELORRUSIA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "MM",
        contenido: "BIRMANIA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BO",
        contenido: "BOLIVIA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BA",
        contenido: "BOSNIA Y HERZEGOVINA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BW",
        contenido: "BOTSUANA",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BR",
        contenido: "BRASIL",
        fecha_ingreso: "2014/04/02 04:54:28"
    },
    {
        codigo: "BN",
        contenido: "BRUNÉI",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "BG",
        contenido: "BULGARIA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "BF",
        contenido: "BURKINA FASO",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "BI",
        contenido: "BURUNDI",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CV",
        contenido: "CABO VERDE",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "KH",
        contenido: "CAMBOYA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CM",
        contenido: "CAMERÚN",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "TD",
        contenido: "CHAD",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CL",
        contenido: "CHILE",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "PR",
        contenido: "PUERTO RICO",
        fecha_ingreso: "2014/04/02 04:54:33"
    },
    {
        codigo: "CA",
        contenido: "CANADÁ",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "US",
        contenido: "ESTADOS UNIDOS DE AMÉRICA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "BQ",
        contenido: "BONAIRE, SAN EUSTAQUIO Y SABA",
        fecha_ingreso: "2019/10/16 22:50:54"
    },
    {
        codigo: "CN",
        contenido: "CHINA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CY",
        contenido: "CHIPRE",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "VA",
        contenido: "CIUDAD DEL VATICANO",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CO",
        contenido: "COLOMBIA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "KM",
        contenido: "COMORAS",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CG",
        contenido: "CONGO",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CD",
        contenido: "CONGO",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "KP",
        contenido: "COREA DEL NORTE",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "KR",
        contenido: "COREA DEL SUR",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CI",
        contenido: "COSTA DE MARFIL",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CR",
        contenido: "COSTA RICA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "HR",
        contenido: "CROACIA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "CU",
        contenido: "CUBA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "DK",
        contenido: "DINAMARCA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "DM",
        contenido: "DOMINICA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "EC",
        contenido: "ECUADOR",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "EG",
        contenido: "EGIPTO",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "SV",
        contenido: "EL SALVADOR",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "AE",
        contenido: "EMIRATOS ÁRABES UNIDOS",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "ER",
        contenido: "ERITREA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "SK",
        contenido: "ESLOVAQUIA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "SI",
        contenido: "ESLOVENIA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "ES",
        contenido: "ESPAÑA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "EE",
        contenido: "ESTONIA",
        fecha_ingreso: "2014/04/02 04:54:29"
    },
    {
        codigo: "ET",
        contenido: "ETIOPÍA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "PH",
        contenido: "FILIPINAS",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "FI",
        contenido: "FINLANDIA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "FJ",
        contenido: "FIYI",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "FR",
        contenido: "FRANCIA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GA",
        contenido: "GABÓN",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GM",
        contenido: "GAMBIA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GE",
        contenido: "GEORGIA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GH",
        contenido: "GHANA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GI",
        contenido: "GIBRALTAR",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GD",
        contenido: "GRANADA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GR",
        contenido: "GRECIA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GL",
        contenido: "GROENLANDIA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GP",
        contenido: "GUADALUPE",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GU",
        contenido: "GUAM",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GT",
        contenido: "GUATEMALA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GF",
        contenido: "GUAYANA FRANCESA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GG",
        contenido: "GUERNSEY",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GN",
        contenido: "GUINEA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GQ",
        contenido: "GUINEA ECUATORIAL",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GW",
        contenido: "GUINEA-BISSAU",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "GY",
        contenido: "GUYANA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "HT",
        contenido: "HAITÍ",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "HN",
        contenido: "HONDURAS",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "HK",
        contenido: "HONG KONG",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "HU",
        contenido: "HUNGRÍA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "IN",
        contenido: "INDIA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "ID",
        contenido: "INDONESIA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "IR",
        contenido: "IRÁN",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "IQ",
        contenido: "IRAK",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "IE",
        contenido: "IRLANDA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "BV",
        contenido: "ISLA BOUVET",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "IM",
        contenido: "ISLA DE MAN",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "CX",
        contenido: "ISLA DE NAVIDAD",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "NF",
        contenido: "ISLA NORFOLK",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "IS",
        contenido: "ISLANDIA",
        fecha_ingreso: "2014/04/02 04:54:30"
    },
    {
        codigo: "BM",
        contenido: "ISLAS BERMUDAS",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "KY",
        contenido: "ISLAS CAIMÁN",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "CC",
        contenido: "ISLAS COCOS (KEELING)",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "CK",
        contenido: "ISLAS COOK",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "AX",
        contenido: "ISLAS DE ÅLAND",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "FO",
        contenido: "ISLAS FEROE",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "GS",
        contenido: "ISLAS GEORGIAS DEL SUR Y SANDWICH DEL SUR",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "HM",
        contenido: "ISLAS HEARD Y MCDONALD",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "MV",
        contenido: "ISLAS MALDIVAS",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "FK",
        contenido: "ISLAS MALVINAS",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "MP",
        contenido: "ISLAS MARIANAS DEL NORTE",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "MH",
        contenido: "ISLAS MARSHALL",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "PN",
        contenido: "ISLAS PITCAIRN",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "SB",
        contenido: "ISLAS SALOMÓN",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "TC",
        contenido: "ISLAS TURCAS Y CAICOS",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "UM",
        contenido: "ISLAS ULTRAMARINAS MENORES DE ESTADOS UNIDOS",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "VG",
        contenido: "ISLAS VÍRGENES BRITÁNICAS",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "VI",
        contenido: "ISLAS VÍRGENES DE LOS ESTADOS UNIDOS",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "IL",
        contenido: "ISRAEL",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "IT",
        contenido: "ITALIA",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "JM",
        contenido: "JAMAICA",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "JP",
        contenido: "JAPÓN",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "JE",
        contenido: "JERSEY",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "JO",
        contenido: "JORDANIA",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "KZ",
        contenido: "KAZAJISTÁN",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "KE",
        contenido: "KENIA",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "KG",
        contenido: "KIRGIZSTÁN",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "KI",
        contenido: "KIRIBATI",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "KW",
        contenido: "KUWAIT",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "LB",
        contenido: "LÍBANO",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "LA",
        contenido: "LAOS",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "LS",
        contenido: "LESOTO",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "LV",
        contenido: "LETONIA",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "LR",
        contenido: "LIBERIA",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "LY",
        contenido: "LIBIA",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "LI",
        contenido: "LIECHTENSTEIN",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "LT",
        contenido: "LITUANIA",
        fecha_ingreso: "2014/04/02 04:54:31"
    },
    {
        codigo: "LU",
        contenido: "LUXEMBURGO",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MX",
        contenido: "MÉXICO",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MC",
        contenido: "MÓNACO",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MO",
        contenido: "MACAO",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MK",
        contenido: "MACEDÔNIA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MG",
        contenido: "MADAGASCAR",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MY",
        contenido: "MALASIA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MW",
        contenido: "MALAWI",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "ML",
        contenido: "MALI",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MT",
        contenido: "MALTA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MA",
        contenido: "MARRUECOS",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MQ",
        contenido: "MARTINICA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MU",
        contenido: "MAURICIO",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MR",
        contenido: "MAURITANIA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "YT",
        contenido: "MAYOTTE",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "FM",
        contenido: "MICRONESIA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MD",
        contenido: "MOLDAVIA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "MN",
        contenido: "MONGOLIA",
        fecha_ingreso: "2014/04/02 04:54:32"
    },
    {
        codigo: "CW",
        contenido: "CURAÇAO",
        fecha_ingreso: "2019/10/16 22:50:54"
    },
    {
        codigo: "SS",
        contenido: "SUDÁN DEL SUR",
        fecha_ingreso: "2019/10/16 22:50:54"
    },
    {
        codigo: "SX",
        contenido: "SINT MAARTEN (PARTE NEERLANDESA)",
        fecha_ingreso: "2019/10/16 22:50:54"
    }]