import { createSlice } from '@reduxjs/toolkit';
import axs from '@axios/axs';

//const indexCatalogos = ['PR', 'CA', 'PA', 'NA', 'CI'];
const indexCatalogos = ['CA'];

const initialState = {
    provincias: [],
    cantones: [],
    parroquias: [],
    nacionalidades: [],
    estados_civiles: [],

    error: false,
    loading: false
};


const slice = createSlice({
    name: 'catalogos',
    initialState,
    reducers: {
        getCatalogosFetch(state) {
            state.loading = true;
            state.error = false;
        },
        getCatalogosSuccess(state, action) {
            const { payload } = action;
            state.provincias = payload.PR || [];
            state.cantones = payload.CA || [];
            state.parroquias = payload.PA || [];
            state.nacionalidades = payload.NA || [];
            state.estados_civiles = payload.CI || [];
            state.error = false;
            state.loading = false;
        },
        getCatalogosError(state, action) {
            state.error = action.payload;
            state.loading = false;
        }
    }
});


export const getCatalogos = () => async (dispatch) => {
    const { getCatalogosFetch, getCatalogosSuccess, getCatalogosError } = slice.actions;
    console.log('getCatalogos');
    try {
        dispatch(getCatalogosFetch());
        const dataCatalogos = {}

        await Promise.all(indexCatalogos.map(async (value) => {
            const { data } = await axs.get(`https://bckfg2.fondosgenesis.com/transactional/catalogos/${value}`, {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            });
            console.log({ data });
            dataCatalogos[value] = data;
        }))

        dispatch(getCatalogosSuccess(dataCatalogos));
    } catch (e) {
        console.error(e);
        dispatch(getCatalogosError(e));
    }
};

export default slice.reducer;