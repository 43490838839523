import axios from 'axios';

export const axs = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'x-access-token': `${typeof window !== 'undefined' ? localStorage.getItem('token') : ''}`,
    'Authorization': `Bearer ${typeof window !== 'undefined' ? localStorage.getItem('token') : ''}`,
  },
});
