import axios from 'axios';
import axiosRetry from 'axios-retry';

const cs_axs = axios.create({
    baseURL: process.env.API_URL,
    headers: {
        //'Content-Type': 'application/json',
        'Accept': 'application/json',
        'guard': 'GUEST',
        'Access-control-allow-origin': '*',
    },
});

axiosRetry(cs_axs, { retries: 5 });

export default cs_axs