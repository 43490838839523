"use client";

import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';


const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: [thunk]
});


export const useSelector = useReduxSelector;
export const useDispatch = () => useReduxDispatch();

export default store;
