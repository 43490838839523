import { createSlice } from '@reduxjs/toolkit';
import cs_axs from '@axios/cs_axs';
import _ from 'lodash';

const parseEntradas = (entradas) => {
    const categorias = entradas.filter(item => item.vista === "categorías");

    const siteMap = categorias.map(categoria => {
        const subcategoria = entradas.filter(entrada => ((entrada.entrada && entrada.entrada.id) === categoria.id));
        return {
            ...categoria,
            subcategoria: subcategoria.map(subItem => {
                return {
                    ...subItem,
                    subcategoriaChild: entradas.filter(entrada => ((entrada.entrada && entrada.entrada.id) === subItem.id))
                }
            })
        }
    });

    return siteMap;
}




const initialState = {
    entradas: [],
    seguridadYDatos: [],
    servicios: [],
    entrada: {
        id: false,
        orden: null,
        slug: null,
        titulo: null,
        descripcion_corta: null,
        descripcion_larga: null,
        vista: null,
        fotoUrl: null,
        caracteristicas: [],
        acciones: [],
        detalles: [],
        entradas: []
    },
    loading: false,
    error: false,
    loaded: false,

};

// find, findOne, create, update, remove

const slice = createSlice({
    name: 'entrada',
    initialState,
    reducers: {
        //CREATE
        createFetch(state) {
            state.loading = true;
            state.error = false;
        },
        createSuccess(state, action) {
            const entradas = [...state.entradas];
            entradas.push(action.payload);
            state.loading = false;
            state.entradas = entradas;
        },
        createError(state, action) {
            state.loading = false;
            state.error = true;
        },
        //READ
        findFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findSucess(state, action) {

            let entradas = action.payload.map(item => (
                {
                    ...item,
                    titulo: item.titulo.replaceAll("*", ""),
                    entradas: _.orderBy(item.entradas.map(entrada => ({
                        ...entrada,
                        titulo: entrada.titulo.replaceAll("*", "")
                    })),
                        item => item.orden, 'desc'
                    )
                }
            ));

            let servicios = entradas.filter(item => item.vista === 'servicios');
            servicios = _.orderBy(servicios, item => item.entrada, 'desc');

            let seguridadYDatos = entradas.find(item => item.slug === 'seguridad_y_datos');
            seguridadYDatos = seguridadYDatos.entradas.length ? seguridadYDatos.entradas : []
            seguridadYDatos = _.orderBy(seguridadYDatos, item => item.orden, 'asc');


            state.siteMap = parseEntradas(action.payload);
            state.entradas = entradas;
            state.servicios = servicios;
            state.seguridadYDatos = seguridadYDatos;
            state.loading = false;
            state.loaded = true;
        },
        findError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },

        // READ BY ID
        findOneFetch(state) {
            state.loading = true;
            state.entrada = initialState.entrada
            state.error = false;
        },
        findOneSucess(state, action) {
            //busco el inidice
            const index = state.entradas.findIndex(item => item.id == action.payload.id);
            // reemplazo en un auxiiliar
            const entradas = [...state.entradas];
            entradas[index] = action.payload;

            state.entradas = entradas;
            state.entrada = action.payload;
            state.loading = false;
        },
        findOneError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },

        //UPDATE
        updateFetch(state) {
            state.loading = true;
            state.error = false;
        },
        updateSucess(state, action) {
            //busco el inidice
            const index = state.entradas.findIndex(item => item.id == action.payload.id);
            // reemplazo en un auxiiliar
            const entradas = [...state.entradas];
            entradas[index] = action.payload;
            //modifico store
            state.loading = false;
            state.entradas = entradas;
        },
        updateError(state, action) {

            state.error = action.payload;
            state.loading = false;
        },
        updateNestedSuccess(state, action) {
            const nestedEntradas = [...state.entrada.entradas];
            const target = state.entradas.find(item => item.id === action.payload.idChildren)

            nestedEntradas.push(target);
            const entrada = {
                ...state.entrada,
                entradas: nestedEntradas
            };
            state.loading = false;
            state.entrada = entrada;
        },
        removeNestedSuccess(state, action) {
            const nestedEntradas = [...state.entrada.entradas];
            const removed = nestedEntradas.filter(item => item.id !== action.payload.idChildren);

            const entrada = {
                ...state.entrada,
                entradas: removed
            };
            state.loading = false;
            state.entrada = entrada;
        },
        cleanForm(state, action) {
            state.entrada = initialState.entrada;
        },

        //entrada_has_documentos

        //


        //DELETE
        deleteFetch(state) {
            state.loading = true;
            state.error = false;
        },
        deleteSuccess(state, action) {
            //busco el inidice
            const index = state.entradas.findIndex(item => item.id == action.payload);
            // reemplazo en un auxiiliar
            const entradas = [...state.entradas];
            entradas.splice(index, 1)
            //modifico store
            state.loading = false;
            state.entradas = entradas;
        },
        deleteError(state, action) {

            state.error = action.payload;
            state.loading = false;
        },
    }
});



export const findEntradas = () => async (dispatch) => {
    const { findFetch, findError, findSucess } = slice.actions;
    try {
        dispatch(findFetch());
        const { data } = await cs_axs.get(`/cms/entradas`);
        dispatch(findSucess(data));
    } catch (e) {
        dispatch(findError(true));
    }
}

export const cleanForm = () => async (dispatch, callback) => {
    dispatch(slice.actions.cleanForm());
    callback();
}

export const findEntrada = (id) => async (dispatch, callback) => {
    const { findOneFetch, findOneSucess, findOneError } = slice.actions;
    try {
        dispatch(findOneFetch());
        const { data } = await cs_axs.get(`/cms/entradas/${id}`);
        callback();
        dispatch(findOneSucess(data));
    } catch (e) {
        dispatch(findOneError(e));
    }
}
export const deleteEntrada = (id) => async (dispatch) => {

    const { deleteFetch, deleteSuccess, deleteError } = slice.actions;
    try {
        dispatch(deleteFetch());
        const response = await cs_axs.delete(`/cms/entradas/${id}`);
        const { data } = response;
        // debugger;
        if (data.affected) {
            dispatch(deleteSuccess(id));
        }
    } catch (e) {
        // debugger;
        dispatch(deleteError(e));
    }
}


export const createEntrada = (entrada) => async (dispatch, callback) => {

    const { createSuccess, createError, createFetch } = slice.actions;
    try {
        dispatch(createFetch());
        const { data } = await cs_axs.post(`/cms/entradas`, entrada);
        callback();
        dispatch(createSuccess(data));
    } catch (e) {
        console.error(e)
        dispatch(createError(e));
    }
}

export const updateEntrada = (entrada, callback) => async (dispatch) => {
    const { updateFetch, updateSucess, updateError } = slice.actions;
    try {
        dispatch(updateFetch());
        const { data } = await cs_axs.put(`/cms/entradas/${entrada.id}`, entrada);
        dispatch(updateSucess(data));
        callback();

        const isChild = data.entrada;
        const isChildRemove = entrada.entrada == null && true;
        // isChild ? dispatch(updateNestedSuccess(data)) :
        //     isChildRemove ? dispatch(removeNestedSuccess(data)) :
    } catch (error) {
        const htmlResponse = error.response;
        dispatch(updateError(htmlResponse.data));
    }
}


export const addNestedEntrada = (idPadre, idChildren) => async (dispatch) => {
    const { updateFetch, updateSucess, updateError, updateNestedSuccess } = slice.actions;
    try {
        dispatch(updateFetch());
        await cs_axs.put(`/cms/subEntradas/${idPadre}/${idChildren}`);

        dispatch(updateNestedSuccess({ idPadre, idChildren }))
    } catch (e) {
        console.error(e)
        dispatch(updateError(e));
    }
}

export const removeNestedEntrada = (idPadre, idChildren) => async (dispatch) => {
    const { updateFetch, updateSucess, updateError, removeNestedSuccess } = slice.actions;
    try {
        dispatch(updateFetch());
        await cs_axs.delete(`/cms/subEntradas/${idPadre}/${idChildren}`);
        dispatch(removeNestedSuccess({ idPadre, idChildren }));
    } catch (e) {
        console.error(e)
        dispatch(updateError(e));
    }
}






///






export default slice.reducer;




