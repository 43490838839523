import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import cs_axs from '@axios/cs_axs';


const initialState = {
    detalleItems: [],
    detalleItem: {
        id: null,
        titulo: null,
        descripcion: null,
        url: null,
        orden: null,
    },
    loading: false,
    error: false,
    loaded: false
};

// find, findOne, create, update, remove

const slice = createSlice({
    name: 'detalleItems',
    initialState,
    reducers: {
        createFetch(state) {
            state.loading = true;
            state.error = false;
        },
        createSuccess(state, action) {
            const entrada = state.entrada;
            //
            state.loading = false;
            state.detalleItems = [...state.detalleItems, action.payload];
        },
        createError(state, action) {
            state.loading = false;
            state.error = true;
        },
        updateFetch(state) {
            state.loading = true;
            state.error = false;
        },
        updateSuccess(state, action) {
            // const index = state.detalleItems.findIndex(item => item.id == action.payload.id);
            // const aux = [...initialState.detalleItems];
            // aux[index] = action.payload;
            // state.detalleItems = aux;

            state.detalleItems = [...state.detalleItems.map(item => item.id == action.payload.id ? action.payload : item)];
            //modifico store
            state.loading = false;
        },
        updateError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        fillForm(state, action) {

            state.detalleItem = action.payload;
        },
        toggleLoading(state, action) {
            state.loading = action.payload;
        },
        setDetalleItems(state, action) {
            state.detalleItems = action.payload;
        }
    },

});


export const createDetalleItem = (payload) => async (dispatch, callback) => {
    const { createSuccess, createError, createFetch } = slice.actions;

    try {
        dispatch(createFetch());
        const { data } = await cs_axs.post(`/cms/detalleItems`, payload);
        callback();
        dispatch(createSuccess(data));
    } catch (e) {
        console.error(e)
        dispatch(createError(e));
    }
}


export const updateDetalleItem = (payload) => async (dispatch, callback) => {
    const { updateFetch, updateSuccess, updateError } = slice.actions;
    try {
        dispatch(updateFetch());
        const { data } = await cs_axs.put(`/cms/detalleItems/${payload.id}`, payload);
        callback();
        dispatch(updateSuccess(data));
    } catch (e) {
        console.error(e)
        dispatch(updateError(e));
    }
}


export const deleteDetalleItem = (id) => async (dispatch, callback) => {
    try {
        dispatch(slice.actions.toggleLoading(true));
        const response = await cs_axs.delete(`/cms/detalleItems/${id}`);
        if (response && response.data && response.data.id) {
            callback();
            dispatch(slice.actions.toggleLoading(false));
        }
    } catch (e) {
        message.error('Lo sentimos intente nuevamente');
        dispatch(slice.actions.toggleLoading(false));
    }
}


export const fillFormDetalleItem = (payload) => async (dispatch, cb) => {
    dispatch(slice.actions.fillForm(payload))
}


export const { setDetalleItems } = slice.actions;

export default slice.reducer;




