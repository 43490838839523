"use client";

import { createSlice } from '@reduxjs/toolkit';
import axs from '@axios/axs';

const init = {
    res: null
};

const slice = createSlice({
    name: "ldap",
    initialState: init,
    reducers: {
        validarUsuarioFetch(state, action) {
            state.res = action.payload
        },
        validarUsuarioOk(state, action) {
            state.res = action.payload

        },
        validarusuarioError(state, action) {
            state.res = action.payload

        }
    }
})


export default slice.reducer;

export const validarUsuario = (codigo_cedula_cliente, callback) => async (dispatch) => {
    const { validarUsuarioFetch, validarusuarioError, validarUsuarioOk } = slice.actions;

    validarUsuarioFetch({ loading: true });
    //
    try {
        const res = await axs.get(`${process.env.API_URL}/auth/ldap/ValidarUsuario/${codigo_cedula_cliente}`);
        //
        callback();
        validarUsuarioOk(res.data)
    } catch (e) {
        console.error(e)
        validarusuarioError(e)
    }
}