import jwtDecode from 'jwt-decode';
import { message, notification } from 'antd';
import { axs } from './axsBaseUrl';

export const isValidToken = (accessToken) => {
    if (!accessToken) return false
    const decoded = jwtDecode(accessToken);
    const valid = decoded.exp > (Date.now() / 1000);
    //console.log('valid ' + valid)
    return valid;
};


export const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('token', accessToken);
        axs.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('siniestros');
        delete axs.defaults.headers.common.Authorization;
    }
};



export const getSession = () => {
    let token = localStorage.getItem('token')
    return isValidToken(token)
}



export const validateCodigo = async (consulta) => {
    //console.log(consulta);
    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.post(`/user/validarCodigo`, consulta, { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        console.log(data);
        if (data.ok) {
            //message.success(data.message);
            notification.success({
                message: 'Código válido',
                description: data.message,
            });
        }

        if (!data.ok) {
            //message.warning(data.message);
            notification.warning({
                message: 'Código inválido',
                description: data.message,
                duration: 8,
            });
        }

        return data;
    }
    catch (e) {
        message.error('Error interno del servidor!');
        // return res.json({ ok: false, message: 'Error interno del servidor!' });
    }
}


// VALIDAR QUE NO DEBE ENVIAR CLAVE ACTUAL
export const cambiarPassword = async (consulta) => {
    //console.log(consulta);
    try {
        const token = localStorage.getItem('tokenBefore') || '';
        const { data } = await axs.post(`/user/cambiarPassword`, consulta, { headers: { 'guard': 'BEFORETRANSAC', Authorization: `Bearer ${token}`, 'x-access-token': token } });

        //console.log(data);
        //message.success(data.message);
        data.ok ?
            notification.success({
                message: '¡Completado!',
                description: data.message,
            })
            : notification.warning({
                message: '¡Aviso!',
                description: data.message,
                duration: 8,
            })

        return data;
    }
    catch (e) {
        //message.error('Error interno del servidor!');
        notification.error({
            message: 'Error',
            description: 'Error interno del servidor!',
        });
        // return res.json({ ok: false, message: 'Error interno del servidor!' });
    }
}


export const getAge = (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}


export const validaCoberturaSiniestro = async (consulta) => {

    const token = localStorage.getItem('token') || '';
    const { data } = await axs.post(`/transactional/siniestros/validaCobertura`, consulta,
        {
            headers: {
                'guard': 'TRANSAC',
                Authorization: `Bearer ${token}`,
                'x-access-token': token
            }
        });

    //console.log(data);

    if (data.ok) {
        return { ok: true, message: 'Cobertura válida', data };
    } else {
        return { ok: false, message: data.novedad, data };
    }
}
