import { createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { setSession } from '@axios/utils';
import axs from '@axios/axs';
//import { findResumen, resetState } from './resumenPersona';





const initialState = {
    user: null,
    ldap: null,
    token: null,
    loading: false,
    loadingLdap: false,
    loadingSession: false,
    redirectToNewForm: false,
    redirectToPassword: false,
    error: false,
    errorLdap: false,
    routes: []
};

const slice = createSlice({
    name: 'login',
    initialState,
    reducers: {

        resetStateLogin: (state) => {
            state.user = null,
                state.ldap = null,
                state.token = null,
                state.loading = false,
                state.loadingLdap = false,
                state.loadingSession = false,
                state.redirectToNewForm = false,
                state.redirectToPassword = false,
                state.error = false,
                state.errorLdap = false
        },

        // LOGIN
        loginFetch(state) {
            state.loading = true;
            state.loadingSession = true;
            state.error = false;
        },
        loginSucess(state, action) {
            state.user = action.payload.user;
            state.token = action.payload.token;
            state.loading = false;
            state.loadingLdap = false;
            state.loadingSession = false;
            state.error = false;
            state.errorLdap = false;
        },
        loginError(state, action) {
            state.error = action.payload;
            state.loading = false;
            state.loadingLdap = false;
            state.loadingSession = false;
        },

        // LOGIN LDAP
        loginLdapFetch(state) {
            state.loadingLdap = true;
            state.ldap = null;
            state.errorLdap = false;
            state.redirectToPassword = false;
        },
        loginLdapSucess(state, action) {
            state.ldap = action.payload;
            state.redirectToPassword = true;
            state.loadingLdap = false;
            state.errorLdap = false;
        },
        loginLdapError(state, action) {
            state.redirectToPassword = false;
            state.errorLdap = action.payload;
            state.loadingLdap = false;
        },
        updateLdap(state, action) {
            state.ldap = action.payload;
        },
        updateLdapRedirect(state, action) {
            state.redirectToPassword = action.payload;
            if (action.payload === false) state.ldap = null;
        },
        updateRedirectNewForm(state, action) {
            state.redirectToNewForm = action.payload;
        },


        //Logout
        logOutFetch(state) {
            state.user = null;
            state.ldap = null;
            state.token = null;
            state.loading = false;
            state.redirectToPassword = false;
            state.loadingLdap = false;
            state.loadingSession = false;
            state.error = false;
        },


        //MENU
        setMenu(state, action) {
            const auxRoutes = [...action.payload.routes];
            //CARGA NOTIFICACIONES EN MENU // CUENTA LEIDAS
            const auxNotiIndex = auxRoutes.findIndex(item => item.key === 'notificaciones');
            const countLeidas = action.payload.notificaciones.find(item => item.leida === false) ? false : true;
            auxRoutes[auxNotiIndex].leida = countLeidas,
                auxRoutes[auxNotiIndex].routes = action.payload.notificaciones.map(({ titulo, descripcion, id, imagen, leida }) => ({
                    id,
                    titulo,
                    slug: `notificaciones/${id}`,
                    descripcion,
                    leida,
                    imagen
                }))
            //SET STATE;
            state.routes = auxRoutes;
        }



    }
});


export const {
    resetStateLogin,
    loginFetch,
    loginSucess,
    loginError,
    loginLdapFetch,
    loginLdapSucess,
    loginLdapError,
    updateLdap,
    updateLdapRedirect,
    updateRedirectNewForm,
    logOutFetch } = slice.actions;


export const LoginApp = (consulta) => async (dispatch) => {
    const { loginFetch, loginSucess, loginError } = slice.actions;
    try {
        dispatch(loginFetch());
        const { data } = await axs.post(`${process.env.API_URL}/user/login`, consulta);
        // (data);
        if (data.ok && data.user && data.token) {
            dispatch(loginSucess({ user: data.user, ldap: data.ldap, token: data.token }));
            setSession(data.token);
            message.success('Inicio de Sesión Exitoso');
        }

        if (!data.ok || !data.token) {
            dispatch(loginError(data.message));
            message.error(data.message);
            setSession(null); // ESTO NO ES REDUX
        }

    }
    catch (e) {
        message.error('Error interno del servidor!');
        dispatch(loginError(e));
        setSession(null); // ESTO NO ES REDUX
    }
}


export const LogOutApp = () => async (dispatch) => {
    setSession(); // vuela axios vuela localStorage;
    dispatch(resetStateLogin());
    //dispatch(resetState());
}


export const loginUsersMe = () => async (dispatch) => {
    const { loginFetch, loginSucess, loginError } = slice.actions;
    dispatch(loginFetch());
    const token = localStorage.getItem('token') || null;

    if (token == null || !token || token == undefined) {
        dispatch(loginError("NO HAY TOKEN EN EL STORAGE"));
        //setSession(null); // ESTO NO ES REDUX
        return;
    }

    try {
        const { data } = await axs.get(`${process.env.API_URL}/user/me`, { headers: { Authorization: `Bearer ${token}` } });
        //debugger
        if (data.ok && data.user) {
            dispatch(loginSucess({ user: data.user, token }));
            dispatch(consultaLdapUserLoginMe({ usuario: data.user.numero_identificacion }));
            //dispatch(findResumen(data.user.numero_identificacion, token));
        }
        if (data.ok == false) {
            dispatch(loginError(data.message));
            message.error(data.message);
            setSession(null);
        }
    }
    catch (e) {
        message.error('Error interno del servidor!');
        dispatch(loginError(e));
        setSession(null);
    }
}


export const updateUserLogged = (consulta) => async (dispatch) => {
    const { loginFetch, loginSucess, loginError } = slice.actions;
    try {
        dispatch(loginFetch());
        const { data } = await axs.post(`${process.env.API_URL}/user/login`, consulta);
        if (data.ok == false) {
            throw new Error(data.message)
        }

        setSession(data.token);
        dispatch(loginSucess({ user: data.user, token: data.token }));

    }
    catch (e) {
        setSession(null)
        dispatch(loginError(e))
    }
}


/** INICIO SESION NUEVO */

export const consultaLdap = (consulta) => async (dispatch) => {

    try {

        dispatch(updateLdapRedirect(false));

        const { data } = await axs.get(`${process.env.API_URL}/user/ldap/${consulta.usuario}`);

        //no existe
        if (data.cod_error == '99') {
            //return message.error(`${data.message}`);
            return message.error('Atención, usuario no registrado por favor, comunicarse con Servicios al Cliente.')
        }


        if (!data.ok) {

            if (!data.ok && data.openNewForm) {
                message.success(`Debe primero crear una cuenta.`);
                dispatch(updateRedirectNewForm(true));
                return;
            }

            dispatch(loginLdapError(data.message));
            message.error(data.message);
            setSession(null);
        }


        if (data.ok && data.ldap) {

            //if (data.ldap.clientstatus == 'blocked') return message.error(`El usuario se encuentra bloqueado`);
            dispatch(updateLdap(data.ldap));
            dispatch(updateLdapRedirect(true));
        }

    }
    catch (e) {
        message.error('Error interno del servidor!');
        dispatch(loginLdapError(e));
        setSession(null);
    }


}

export const loginLdapApp = (consulta) => async (dispatch) => {
    try {
        const { data } = await axs.post(`${process.env.API_URL}/user/login`, consulta);
        if (data.ok && data.user && data.token) {
            dispatch(loginSucess({ user: data.user, ldap: data.ldap, token: data.token }));
            setSession(data.token);
            message.success('Inicio de Sesión Exitoso');
        }

        if (!data.ok || !data.token) {
            dispatch(loginError(data.message));
            message.error(data.message);
            setSession(null); // ESTO NO ES REDUX
        }

    }
    catch (e) {
        message.error('Error interno del servidor!');
        dispatch(loginError(e));
        setSession(null); // ESTO NO ES REDUX
    }
}


export const setMenu = ({ notificaciones }) => async (dispatch) => {
    const routes = [
        {
            key: "inicio",
            titulo: "Inicio",
            slug: "transacciones",
            icon: "inicio",
        },
        {
            key: "notificaciones",
            titulo: "Notificaciones",
            icon: "notificaciones",
        },
        {
            key: "incremento",
            titulo: "Incremento",
            slug: "transacciones/incremento",
            icon: "incremento"

        },
        // {
        //     titulo: "Rescate",
        //     slug: "rescate"
        // },
        // {
        //     titulo: "Comprobantes",
        //     slug: "comprobantes"
        // },
        // {
        //     titulo: "Beneficiarios",
        //     slug: "beneficiarios"
        // },
        {
            key: "asistencia",
            titulo: "Asistencia",
            icon: "asistencia",
            routes: [
                {
                    key: "siniestros",
                    titulo: "Reportar un siniestro",
                    slug: "siniestros",
                    icon: "siniestros",
                },
                {
                    key: "solicitudes",
                    titulo: "Reportar un problema",
                    slug: "solicitudes",
                    icon: "solicitudes",
                }
            ]
        },
        {
            key: "usuario",
            titulo: "Usuario",
            icon: "usuario",
            slug: "perfil/update",
            routes: [
                {
                    key: "perfil",
                    titulo: "Perfil",
                    slug: "perfil/update",
                    icon: "perfil",
                },
                {
                    key: "logout",
                    titulo: "Cerrar Sesión",
                    slug: "transacciones/logout",
                    icon: "logout",
                }
            ]
        },

        {
            key: "logout",
            titulo: "Cerrar Sesión",
            slug: "transacciones/logout",
            icon: "logout",
        }
    ];

    dispatch(slice.actions.setMenu({ notificaciones, routes }))
}


export const consultaLdapUserLoginMe = (consulta) => async (dispatch) => {

    try {
        const { data } = await axs.get(`${process.env.API_URL}/user/ldap/${consulta.usuario}`);
        if (!data.ok) {
            dispatch(loginLdapError(data.message));
            message.error(data.message);
            setSession(null);
        }

        if (data.ok && data.ldap) {
            dispatch(updateLdap(data.ldap));
        }
    }
    catch (e) {
        message.error('Error interno del servidor!');
        dispatch(loginLdapError(e));
        setSession(null);
    }
}



export default slice.reducer;