import axios from 'axios';
import axiosRetry from 'axios-retry';

const axs = axios.create();

axiosRetry(axs, { retries: 5 });

axs.interceptors.request.use(request => {
    return request;
}, error => {
    return Promise.reject(error);
});


export default axs;