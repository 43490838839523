export const jsFileToBlob = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result)
        };
        fileReader.onerror = error => {
            reject(error)
        };
    });
};

export const handleFiguras = (fiugraId) => {
    const aux = fiugraId
        ? `${process.env.API_URL}/figuras/${fiugraId}.webp`
        : '/img/placeholder.png';
    return aux;
}



export const hideEmail = (data) => {

    if (typeof data === 'string' && data.length >= 6) {
        let atIndex = data.indexOf('@');

        if (atIndex >= 4) {
            let hiddenEmail = data.substring(0, 2) + "****" + data.substring(atIndex - 2);
            return hiddenEmail;
        } else {
            return data; // Devuelve el correo original si no tiene suficientes caracteres antes de '@'
        }
    } else {
        return data; // Devuelve el correo original si no tiene al menos 6 caracteres
    }

}



export const hideCharacter = (texto) => {
    if (texto && texto.length <= 5) {
        return texto;
    } else {
        const primerosDos = texto?.slice(0, 2);
        const ultimosDos = texto?.slice(-2);
        const enmascarado = primerosDos + "*".repeat(texto?.length - 6) + ultimosDos;
        return enmascarado;
    }
}