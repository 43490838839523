import { combineReducers } from '@reduxjs/toolkit';
import entradas from './slices/entradas';
import figuras from './slices/figuras';
import caracteristicas from './slices/caracteristicas';
import detalles from './slices/detalles';
import detalleItems from './slices/detalleItems';
import acciones from './slices/acciones';

import documentos from './slices/documentos';
import entradaHasDocumentos from './slices/entradaHasDocumentos';
import loginReducer from './slices/login';
import catalogosReducer from './slices/catalogos';
import simulacion from './slices/simulacion';
import simuladorCortoPlazo from './slices/simuladorCortoPlazo';
import simuladorHorizonte from './slices/simuladorHorizonte';
import clientePotencial from './slices/clientePotencial';
import usuario from './slices/usuario';
import sesion from './slices/auth/sesion';
import ldap from './slices/auth/ldap'

const rootReducer = combineReducers({
    entradas,
    figuras,
    documentos,
    caracteristicas,
    detalles,
    detalleItems,
    acciones,
    entradaHasDocumentos,
    login: loginReducer,
    catalogos: catalogosReducer,
    simulacion,
    simuladorCortoPlazo,
    simuladorHorizonte,
    clientePotencial,
    usuario,
    sesion,
    ldap,

});

export default rootReducer;