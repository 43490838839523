"use client";

import { createSlice } from '@reduxjs/toolkit';
import cs_axs from '@axios/cs_axs';

const initialState = {
    acciones: [],
    accion: {
        id: null,
        entrada: null,
        titulo: null,
        tipo: "local",
        url: null
    },
    loading: false,
    error: false,

}
const slice = createSlice({
    name: 'acciones',
    initialState,
    reducers: {
        createFetch(state) {
            state.loading = true;
            state.error = false;
        },
        createSuccess(state, action) {
            state.loading = false;
            state.acciones = [...state.acciones, action.payload];
        },
        createError(state, action) {
            state.loading = false;
            state.error = true;
        },

        updateFetch(state) {
            state.loading = true;
            state.error = false;
        },
        updateSuccess(state, action) {
            const index = state.acciones.findIndex(item => item.id == action.payload.id);
            const newList = [...initialState.acciones];
            newList[index] = action.payload;
            state.loading = false;
            state.acciones = newList;
        },
        updateError(state, action) {
            state.error = action.payload;
            state.loading = false;
        },


        fillForm(state, action) {

            state.accion = action.payload;
        },
        cleanForm(state, action) {
            state.accion = initialState.accion;
        },

    }
});


export const createAccion = (payload) => async (dispatch, callback) => {
    const { createSuccess, createError, createFetch } = slice.actions;
    try {
        dispatch(createFetch());
        const { data } = await cs_axs.post(`/cms/acciones`, payload);
        callback();
        dispatch(createSuccess(data));
    } catch (e) {
        console.error(e)
        dispatch(createError(e));
    }
}

export const fillFormAccion = (payload) => async (dispatch) => {
    dispatch(slice.actions.fillForm(payload));
}

export const cleanFormAction = (payload) => async (dispatch) => {
    dispatch(slice.actions.cleanForm());
}

export default slice.reducer;
