import { format, addDays, differenceInDays } from "date-fns";
import { es } from 'date-fns/locale';

/*

*/

const FV = (rate, nper, pmt, pv, type) => {
  // let pow = Math.pow(1 + rate, nper),
  //   fv;
  // pv = pv || 0;
  // type = type || 0;
  // if (rate) {
  //   fv = (pmt * (1 + rate * type) * (1 - pow) / rate) - pv * pow;
  // } else {
  //   fv = -1 * (pv + pmt * nper);
  // }
  // const result = fv.toFixed(2);
  // return result;
}

const FV2 = (rendimiento, plazo, monto) => {
  const rate = rendimiento / 100 / 360;
  const valor = monto * rate * plazo;
  const result = parseFloat(monto) + parseFloat(valor);
  return result.toFixed(2);
}

function PV(rate, num, amount, remain, type) {
  let factor;

  if (type == 0) {
    factor = (rate == 0 ? num : (1 - Math.pow(1 + rate, -num)) / rate);
  } else {
    factor = (rate == 0 ? num : (1 - Math.pow(1 + rate, -num)) * (1 + rate) / rate);
  }

  const pv = -amount * factor - remain * Math.pow(1 + rate, -num);
  return pv.toFixed(2)
}

const IPMT = (pv, pmt, rate, per) => {
  let tmp = Math.pow(1 + rate, per);
  return 0 - (pv * tmp * rate + pmt * (tmp - 1));
};


const FVBREAKDOWN = (rate, nper, pmt, pv, type) => {
  const init = new Date();
  // CALCULA DIAS
  const diasArr = []
  for (let i = 1; i <= nper; i++) {
    const currentDate = addDays(init, i);
    diasArr.push(currentDate)
  }
  let rendimientoAcum = 0;
  const rendimientoDiario = diasArr.map((item, i) => {
    const rendimiento = IPMT(pv, pmt, rate, i + 1);
    rendimientoAcum = parseFloat(rendimientoAcum) + parseFloat(rendimiento);
    return ({
      date: item,

      fecha_string_dia: format(item, 'EEEE dd MMM yyyy', { locale: es }),
      fecha_string: format(item, 'dd MMM yyyy', { locale: es }),

      fecha_mes_ano: format(item, 'MMM yy', { locale: es }),
      fecha_ano_mes_dia: format(item, 'yyyy-MM-dd', { locale: es }),


      rendimiento,
      rendimientoAcum,
      total: -pv + rendimientoAcum
    });
  });


  // CALCULA MENSUAL
  const rendimientoDiarioCopy = [...rendimientoDiario];
  const rendimientoMensual = [];
  let rendimientoAcumMensual = 0;
  rendimientoDiarioCopy.reduce(function (res, value) {
    if (!res[value.fecha_mes_ano]) {
      res[value.fecha_mes_ano] =
      {
        date: value.date,
        fecha_string: value.fecha_string,
        fecha_string_dia: value.fecha_string_dia,
        fecha_mes_ano: value.fecha_mes_ano,
        fecha_ano_mes_dia: value.fecha_ano_mes_dia,


        rendimiento: 0,
        rendimientoAcum: 0,
        total: 0,
      };
      rendimientoMensual.push(res[value.fecha_mes_ano])
    }
    rendimientoAcumMensual = parseFloat(rendimientoAcumMensual) + parseFloat(value.rendimiento);
    res[value.fecha_mes_ano].rendimiento += value.rendimiento;
    res[value.fecha_mes_ano].rendimientoAcum = rendimientoAcumMensual
    res[value.fecha_mes_ano].total = -pv + rendimientoAcumMensual
    return res;
  }, {});

  return { rendimientoMensual, rendimientoDiario };

}



export { FV, FV2, PV, FVBREAKDOWN }