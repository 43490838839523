import { createSlice } from '@reduxjs/toolkit';
import { message, Button } from 'antd';
import cs_axs from '@axios/cs_axs';

const init = {
    usuario: {
        nombres: "",
        correo: "",
        contrasena: "",
        createdAt: "",
        updatedAt: "",
        deletedAt: "",
    },
    usuarios: [],
    loading: false,
    error: false,
};


const createHelper = (list, payload) => [...list, payload];
const updateHelper = (list, payload) => {
    const auxList = [...list];
    const auxFound = list.findIndex(item => item.id === payload.id);
    auxList[auxFound] = payload;
    return auxList;
};
const deleteHelper = (list, payload) => {
    const auxList = list.fiter(item => item.id != payload.id);
    auxList.push(payload.id);
    return auxList;
};

const slice = createSlice({
    name: "usuario",
    initialState: init,
    reducers: {
        createFetch(state) {
            state.loading = true;
            state.error = false;
        },
        createOk(state, action) {
            state.loading = false;
            state.error = false;
            state.usuarios = createHelper(state.usuarios, action.payload);
        },
        createError(state, action) {
            state.error = action.payload
            state.loading = false;
        },
        updateFetch(state) {
            state.loading = true;
            state.error = false;
        },
        updateOk(state, action) {
            state.loading = false;
            state.error = false;
            state.usuarios = updateHelper(state.usuarios, action.payload);
        },
        updateError(state, action) {
            state.error = action.payload
            state.loading = false;
        },
        deleteFetch(state) {
            state.loading = true;
            state.error = false;
        },
        deleteOk(state, action) {
            state.loading = false;
            state.error = false;
            state.usuarios = deleteHelper(state.usuarios, action.payload);
        },
        deleteError(state, action) {
            state.error = action.payload
            state.loading = false;
        },

        findFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findOk(state, action) {
            state.loading = false;
            state.error = false;
            state.usuarios = action.payload;
        },
        findError(state, action) {
            state.error = action.payload
            state.loading = false;
        },
        findOneFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findOneOk(state, action) {
            state.loading = false;
            state.error = false;
            state.usuario = action.payload;
        },
        findOneError(state, action) {
            state.error = action.payload
            state.loading = false;
        },
        loginFetch(state) {
            state.loading = true;
            state.error = false;
        },
        loginOk(state, action) {

            state.loading = false;
            state.error = false;
            state.session = action.payload;
        },
        loginError(state, action) {
            state.error = action.payload
            state.loading = false;
        },


    }
});


export default slice.reducer;



export const createUsuario = (payload, callback) => async (dispatch) => {
    const { createFetch, createOk, createError } = slice.actions;
    try {
        dispatch(createFetch());
        const response = await cs_axs.post(`${process.env.API_URL}/auth/usuario`, payload);
        message.info('Usuario creado con éxito.');
        dispatch(createOk(response.data))
    } catch (error) {

        message.error('Lo sentimos ha ocurrido un error vuelva a intentarlo.');
        error.response ? dispatch(createError(error.response.data))
            : dispatch(createError(error));

    }
};
export const updateUsuario = (payload, callback) => async (dispatch) => {

};
export const deleteUsuario = (payload, callback) => async (dispatch) => {

};
export const findUsuario = (payload, callback) => async (dispatch) => {

};
export const findOneUsuario = (payload, callback) => async (dispatch) => {

};

