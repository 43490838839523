import { createSlice } from '@reduxjs/toolkit';
import { fondosObj } from '@utils/catalogos_negocio';
import { notification } from 'antd';
import cs_axs from '@axios/cs_axs';
import { format } from 'date-fns';

const init = {
    clientePotencial: {
        nombres: "",
        telefono: "",
        correo: "",
        mensaje: "",
        entradaId: "",
        simulacionId: "",
        tiempoInvertir: '',
    },
    loading: false,
    error: false,

}


const cleanClientePotencial = (data) => {
    const today = new Date();
    const aux = data.map(item => {
        const dateContactar = format(today.setHours(item.dateContactar, 0, 0, 0), "h:mm a")
        return {
            ...item, dateContactar
        }
    });
    return aux;
}


const exportPdfParseSimulaciones = (data) => {
    if (data.length) {
        const { codigo_servicio, rendimiento, montoInversion, plazo, aporteMensual, incrementoAporteAnual } = data[0].simulacion;
        return {
            fondo: fondosObj[codigo_servicio],
            rendimiento,
            montoInversion,
            plazo,
            aporteMensual,
            incrementoAporteAnual
        }
    }
    return
}
const exportPdf = (data) => {
    const today = new Date();
    const aux = data.map(item => {
        const dateContactar = format(today.setHours(item.dateContactar, 0, 0, 0), "h:mm a")
        return {
            ...item,
            dateContactar,
            ...exportPdfParseSimulaciones(item.clientePotencialHasSimulacion),
        }
    });
    return [
        {
            sheet: "cliente_potencial",
            columns: [
                {
                    label: 'Teléfono',
                    value: 'telefono'
                },
                {
                    label: 'Correo',
                    value: 'correo'
                },
                {
                    label: 'Llamar',
                    value: 'dateContactar'
                },
                {
                    label: 'Registrado en',
                    value: 'createdAt'
                },
                {
                    label: 'Nombres',
                    value: 'nombres',
                },
                {
                    label: 'Mensaje',
                    value: 'mensaje',
                },
                {
                    label: 'Tiempo a Invertir',
                    value: 'tiempoInvertir'
                },
                {
                    label: 'Fondo',
                    value: 'fondo',
                },
                {
                    label: 'Inversión',
                    value: 'montoInversion',
                },
                {
                    label: 'Plazo',
                    value: 'plazo',
                },
                {
                    label: 'Rendimiento',
                    value: 'rendimiento',
                },
                {
                    label: 'Aporte Mensual',
                    value: 'aporteMensual',
                },
                {
                    label: 'Incremento',
                    value: 'incrementoAporteAnual',
                },
                {
                    label: 'Ciudad Residencia',
                    value: 'ciudadResidencia',
                }

            ],
            content: aux,
        }
    ]
}



const slice = createSlice({
    name: 'clientePotencial',
    initialState: init,
    reducers: {
        createFetch(state) {
            state.loading = true;
            state.error = false;
            state.clientePotencial = init.clientePotencial
        },
        createOk(state, action) {
            const { payload } = action;
            state.loading = false;
            state.error = false;
            state.clientePotencial = action.payload;
        },
        createError(state, action) {
            const { payload } = action;
            state.error = payload
            state.loading = false;
            state.clientePotencial = init.clientePotencial
        },

        findFetch(state) {
            state.loading = true;
            state.error = false;
        },
        findOk(state, action) {
            const { payload } = action
            state.loading = false;
            state.error = false;
            state.clienteHasSimulaciones = payload.clienteHasSimulaciones;
            state.clientePotenciales = cleanClientePotencial(payload.clientePotenciales)
            state.exportPdf = exportPdf(payload.clientePotenciales)


            state.simulaciones = payload.simulaciones.map(item => ({
                ...item,
                descripcion_servicio: fondosObj[item.codigo_servicio]
            }));
        },
        findError(state, action) {
            const { payload } = action;
            state.error = payload
            state.loading = false;
        },
    }
});

export default slice.reducer;


export const createClientePotencial = (payload, callback) => async (dispatch) => {
    console.log({ payload });
    const { createFetch, createOk, createError } = slice.actions;
    try {
        dispatch(createFetch());
        const response = await cs_axs.post(`/cms/clientePotencial`, payload);
        callback();
        dispatch(createOk(response.data));
    } catch (error) {
        dispatch(createError(error));
        notification.error({
            message: 'Lo sentimos hemos tenido un problema al registrar su solicitud',
            placement: 'top'
        })
    }
}

export const findClientePotencial = (payload) => async (dispatch) => {
    const { findFetch, findError, findOk } = slice.actions;
    try {
        dispatch(findFetch());
        const { data } = await cs_axs.get(`/cms/clientePotencial`, {
            params: {
                dateIni: payload[0],
                dateEnd: payload[1]
            }
        });
        data && dispatch(findOk(data))
    } catch (error) {
        dispatch(findError(error));
    }
}